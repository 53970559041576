.coursesContainer {
  position: relative;
}

/* .coursesInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
} */

.coursesContainer h3 {
  font-size: 60px;
  font-weight: 400;
  color: #ff4756;
  line-height: 80px;
  margin: 0 0 27px 0;
  font-style: normal;
  font-family: "Lobster";
  letter-spacing: -0.01em;
}

.course {
  width: 394px;
  height: 485px;
  margin-top: 16px;
  margin-left: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 15px 25px 15px 25px;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.courseImg {
  height: 195px;
}

.blogContent {
  height: 160px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}

.course h4 {
  font-size: 24px;
  color: #00235a;
  font-weight: 700;
  line-height: 32px;
  margin: 16px 0 0 0;
}
.course p {
  font-size: 14px;
  margin-top: 8px;
  color: #828282;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.5%;
}

.courseRating {
  display: flex;
  margin-top: 48px;
  flex-direction: row;
  align-items: center;
}

.courseRating h5 {
  margin: 0;
  font-size: 16px;
  color: #00235a;
  font-weight: 600;
  margin-right: 5px;
  line-height: 24px;
  letter-spacing: 0.75%;
}

.courseFooter {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.courseFooter p {
  margin: 0;
  font-size: 12px;
  color: #ff4756;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 3%;
}

.courseBack {
  top: 300px;
  left: -50px;
  cursor: pointer;
  position: absolute;
}

.courseNext {
  top: 300px;
  right: -50px;
  cursor: pointer;
  position: absolute;
}
