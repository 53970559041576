.footerContent {
  padding: 20px 0;
  background-color: #00235a;
}

.footerLinks a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.followUs {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.4));
}

.social {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom {
  margin: 0;
  opacity: 0.5;
  font-size: 14px;
  margin-top: 38px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 0.015em;
}
