.container h3 {
  font-size: 64px;
  font-weight: 400;
  color: #ff4756;
  line-height: 80px;
  margin: 87px 0 30px 0;
  font-family: "Lobster";
}

.booksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.book {
  width: 394px;
  border-radius: 16px;
  background: #fff;
}

.image {
  width: 394px;
  border-radius: 16px;
  border: 0.5px solid #d1d1d1;
}

.book h4 {
  font-size: 24px;
  color: #00235a;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin: 26px 0 0 0;
}
.book p {
  font-size: 24px;
  margin-top: 15px;
  color: #8e8e93;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}
