.blogContainer {
  margin-top: 28px;
  position: relative;
}

.blogFilter {
  display: flex;
  padding: 8px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #8e8e93;
  line-height: 24px;
  margin: 0 0 27px 0;
  align-items: center;
  border-radius: 60px;
  justify-content: center;
  border: 1px solid #8e8e93;
}

.activeBlog {
  color: #ffffff;
  background: #00235a;
}

.blogContainer h3 {
  font-size: 60px;
  font-weight: 400;
  color: #ff4756;
  line-height: 80px;
  margin: 0 0 27px 0;
  font-style: normal;
  font-family: "Lobster";
  letter-spacing: -0.01em;
}

.blog {
  /* width: 394px; */
  height: 445px;
  margin-top: 32px;
  margin-left: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 15px 25px 15px 25px;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.blogImg {
  height: 195px;
}

.blogInnerContent {
  height: 180px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}

.blog h4 {
  font-size: 24px;
  color: #00235a;
  font-weight: 600;
  line-height: 30px;
  margin: 16px 0 0 0;
}
.blog p {
  font-size: 14px;
  margin-top: 8px;
  color: #828282;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.5%;
}

.blogFooter {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blogFooter p {
  margin: 0;
  font-size: 14px;
  color: #bdbdbd;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 2%;
}

.viewCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.viewCount h6 {
  margin: 0;
  font-size: 14px;
  color: #bdbdbd;
  font-weight: 600;
  margin-right: 3px;
  line-height: 15px;
  letter-spacing: 2%;
}
