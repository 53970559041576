@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
.counterContainer {
  display: flex;
  margin: 100px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.counter {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.counter h4 {
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  font-family: "Lobster", cursive;
}

.counter .first {
  top: 52px;
  left: 55px;
}
.counter .second {
  top: 52px;
  left: 45px;
}
.counter .third {
  top: 50px;
  left: 45px;
}
.counter .fourth {
  top: 45px;
  left: 50px;
}
.counter .fifth {
  top: 45px;
  left: 50px;
}

.counter img {
  width: 135px;
  height: 130px;
}

.counter p {
  font-size: 14px;
  color: #00235a;
  margin-top: 10px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.015em;
}
