.feedbackContainer {
  height: 500px;
  margin-bottom: 100px;
  background-color: #00235a;
}

.feedbackContainer h3 {
  font-size: 48px;
  color: #ffffff;
  padding-top: 40px;
  font-weight: 400;
  line-height: 64px;
  text-align: center;
  margin-bottom: 35px;
  letter-spacing: -0.005em;
  font-family: "Lobster", cursive;
}

.feedback {
  /* height: 308px; */
  padding: 24px;
  border-radius: 20px;
  background: #ffffff;
}

.feedbackImg {
  width: 200px;
  border-radius: 50%;
}

.feedbackContent h4 {
  font-size: 24px;
  color: #ff4756;
  font-weight: 700;
  line-height: 32px;
}

.feedbackContent p {
  font-size: 16px;
  color: #00235a;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.015em;
}
