.aboutUsContainer {
  position: relative;
  margin: 100px 0 0 0;
}

.aboutUsContainer h3 {
  font-size: 60px;
  font-weight: 400;
  color: #ff4756;
  line-height: 80px;
  margin: 0 0 27px 0;
  font-style: normal;
  font-family: "Lobster";
  letter-spacing: -0.01em;
}

.aboutUsContainer img {
  width: 288px;
  height: 378px;
}

.aboutUsContainer p {
  font-size: 16px;
  font-weight: 400;
  color: #00235a;
  line-height: 24px;
  margin: 100 0 30px 0;
  font-style: normal;
  letter-spacing: -0.05em;
}

.aboutUsContainer button {
  border: none;
  width: 145px;
  height: 40px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin-top: 54px;
  line-height: 24px;
  border-radius: 6px;
  background: #00235a;
}
