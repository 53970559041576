@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
.slideshow {
  height: 559px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #00235a;
}
.slideshow h3 {
  font-size: 48px;
  color: #ffffff;
  margin-top: 35px;
  font-weight: 400;
  line-height: 64px;
  text-align: center;
  margin-bottom: 35px;
  letter-spacing: -0.005em;
  font-family: "Lobster", cursive;
}

.slideshowSlider {
  position: relative;
  transition: ease-in 1000ms;
}

.slide {
  width: 100%;
  height: 335px;
  position: absolute;
  border-radius: 12px;
  /* display: inline-block; */
  background-color: #ffffff;
}

.sliderLeft {
  padding-left: 106px;
}
.sliderLeft h4 {
  font-size: 36px;
  color: #ff4756;
  margin-top: 40px;
  font-weight: 400;
  line-height: 44px;
  font-family: "Lobster", cursive;
}
.sliderLeft p {
  font-size: 16px;
  color: #00235a;
  margin-top: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.015em;
}
.sliderLeft button {
  border: none;
  width: 182px;
  height: 40px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin-top: 35px;
  line-height: 24px;
  border-radius: 6px;
  background: #00235a;
}

.dotsContainer {
  display: flex;
  margin-top: 370px;
  align-items: center;
  justify-content: center;
}

.slideshowDots {
  display: flex;
  margin-top: 35px;
  flex-direction: row;
  align-items: center;
}

.slideshowDot {
  width: 14px;
  height: 14px;
  cursor: pointer;
  border-radius: 50%;
  margin: 0px 20px 0px;
  background: #00235a;
  border: 2px solid #ffffff;
}

.slideshowDot.active {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.35);
}
